export const firebaseConfig = {
	apiKey: "AIzaSyDd3yM_-Bokwotv6Oax6FudVyIHt6irtew",
	authDomain: "app-community-money-production.firebaseapp.com",
	databaseURL: "https://app-community-money-production.firebaseio.com",
	projectId: "app-community-money-production",
	storageBucket: "app-community-money-production.appspot.com",
	messagingSenderId: "1014367153121",
	appId: "1:1014367153121:web:1f4a006b3e0e3efd3480a0",
	measurementId: "G-YGNKN5PC10"
}
